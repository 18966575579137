
$(document).on("turbolinks:load", function() {
  $('#invoices-datatable').DataTable({
    // dom: '<"md:w-1/3 md:inline-flex"l><"md:w-1/3 md:inline-flex"B><"md:w-1/3 md:inline-flex md:justify-end"f>rtip',
    // buttons: [
    //   { extend: 'excelHtml5', text: 'Excel'},
    //   { extend: 'pdfHtml5', text: 'PDF',
    //     exportOptions: {
    //       columns: [ 0, 1, 2, 3, 4, 5, 6 ]
    //     } 
    //   },
    // ],
    processing: true,
    serverSide: true,
    responsive: true,
    // stateSave: true,
    language: {
      url: '//cdn.datatables.net/plug-ins/1.11.3/i18n/pt_br.json'
    },
    pageLength: 25,
    lengthMenu: [ [10, 25, 50, -1], [10, 25, 50, "Todos"] ],
    ajax: {
      url: $('#invoices-datatable').data('source')
    },
    pagingType: "full_numbers",
    columns: [
      {data: "first_name"},
      {data: "last_name"},
      {data: "amount_int"},
      {data: "payment_method"},
      {data: "status"},
      {data: "due_date"},
      {data: "issue_date"},
      {data: "dt_actions"},
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  }).columns.adjust().responsive.recalc();
});
import { Controller } from "stimulus"
import Inputmask from "inputmask";
export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    console.log('money_controller')
  }

  initialize() {
    Inputmask(
      'currency', 
      {
        rightAlign: false,
        prefix: 'R$ ',
        radixPoint: ",", 
        numericInput: true, 
        onBeforeMask: function(value, opts) {
          return value;
        } 
      }
    ).mask(this.inputTarget);
  }
}

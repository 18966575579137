
$(document).on("turbolinks:load", function() {
  $('#end-users-datatable').DataTable({
    processing: true,
    serverSide: true,
    responsive: true,
    language: {
      url: '//cdn.datatables.net/plug-ins/1.11.3/i18n/pt_br.json'
    },
    pageLength: 25,
    ajax: {
      url: $('#end-users-datatable').data('source')
    },
    pagingType: "full_numbers",
    columns: [
      {data: "first_name"},
      {data: "last_name"},
      {data: "email"},
      {data: "dt_actions"},
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  }).columns.adjust().responsive.recalc();
});
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'source' ]
  
  toggle(event) {
    event.preventDefault()
    let id = this.sourceTarget.dataset.id
    let collapse_div = document.getElementById(id)
    $(collapse_div).slideToggle('fast')
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["close"];

  connect() {
    console.log('flash.connect')
  }

  dismiss() {
    var element = this.element;
    element.remove();
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('loading_controller')
  }

  showLoading() {
    Swal.fire({
      html: 'Aguarde...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      width: '16rem',
      didOpen: () => {
        Swal.showLoading()
      }
    });
  }

  hideLoading() {
    Swal.hideLoading()
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy(event) {
    event.preventDefault()
    this.sourceTarget.select()
    document.execCommand("copy")

    var tooltip = document.getElementById("tooltip")
    tooltip.innerHTML = "Copiado!"
  }

  mouseOut() {
    var tooltip = document.getElementById("tooltip")
    tooltip.innerHTML = "Copiar"
  }
}
import { Controller } from "stimulus"
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    console.log('document_controller')
  }

  initialize() {
    Inputmask({ mask: ['999.999.999-99', '99.999.999/9999-99'], keepStatic: true }).mask(this.inputTarget);
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "creditCardNumber" ]

  connect() {
    console.log('credit_card_controller')
  }

  disableCopy(event) {
    event.preventDefault();
  }

  disablePaste(event) {
    event.preventDefault();
  }

  disableCut(event) {
    event.preventDefault();
  }

  creditCardMask() {
    Inputmask(
      ["9999 999999 99999", "9999 9999 9999 9999"],
      { 
        keepStatic: true,
        inputmode: "numeric",
        placeholder: " ",
        onBeforePaste: function(pastedValue, opts) { return false }
      }
    ).mask(this.creditCardNumberTarget);
  }

  onlyNumbersMask(event) {
    Inputmask(
      {
        regex: "[0-9]*",
        placeholder: "",
        rightAlign: false,
      }
    ).mask(event.target);
  }
}
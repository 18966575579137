import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "input" ]

    connect() {
        console.log('verify_split_controller')
    }

    checkTotalAmount() {
        let invoiceAmountLabel = document.getElementById('invoice_amount')
        let invoiceAmount = invoiceAmountLabel.dataset.amount
        let invoiceAmountInt = parseInt(invoiceAmount.replace(',', ''))

        let splitSumItems = document.querySelectorAll('.company-split-amount-input')
        let splitSumValues = [...splitSumItems].map(this.currencyToCents)
        let splitSumInt = splitSumValues.reduce((a, b) => a + b, 0);

        this.allowDenyPaymentButton(splitSumInt, invoiceAmountInt);
    }

    currencyToCents = i => parseInt(i.value.replace('R$', '').replace(',', '').replace('.', ''));

    allowDenyPaymentButton(sumValues, amountInt) {
        let invoiceAmountLabel = document.getElementById('invoice_amount')
        let allowPaymentButton = document.querySelector('.allow_payment')

        if (sumValues !== amountInt) {
            invoiceAmountLabel.classList.add('text-red-500');
            allowPaymentButton.disabled = true;
        } else {
            invoiceAmountLabel.classList.remove('text-red-500');
            allowPaymentButton.disabled = false;
        }
    }
}

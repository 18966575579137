//
// https://webpack.js.org/guides/dependency-management/#context-module-api
//
// function importAll (r) {
//   r.keys().forEach(r);
// }
// importAll(require.context('./', true, /\.js$/));

const datatables = require.context('.', true, /\.js$/)
datatables.keys().forEach(datatables)

// Evita que o datatables seja inicializado de novo quando é usado o voltar do navegador
document.addEventListener("turbolinks:before-cache", function() {
  if ($('[id*=datatable_wrapper]').length == 1) {
    $('[id*=datatable_wrapper]').find('table').DataTable().destroy();
  }
});
  

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["company"];

  changeCompany() {
    var comapanyID = this.companyTarget.value
    var company    = !!comapanyID;
    var location   = company ? `/cia/${comapanyID}/dashboard` : '/';

    Turbolinks.visit(location);
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("jquery")
require("@nathanvda/cocoon")
require('pdfmake')
require('datatables.net-dt')
require('datatables.net-responsive-dt')
require('datatables.net-buttons-dt')
require('datatables.net-buttons/js/buttons.html5')

window.Rails = Rails

import jsZip from 'jszip'
window.JSZip = jsZip

import pdfFonts from "pdfmake/build/vfs_fonts"
pdfMake.vfs = pdfFonts.pdfMake.vfs

// Descomentar essa linha se quiser usar jquery nas views
window.$ = window.jQuery = jQuery;

import "@fortawesome/fontawesome-free/js/all"

// Manter o import do application no final das importações de arquivos CSS
import "styles/application"

import "controllers"

require.context('../images', true)

import 'application/sweet-alert-confirm'
import 'chartkick/chart.js'
import 'datatable'
import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }
  // urlValue
  static targets = [ "charge", "bankBillet" ] 

  connect() {
    console.log('external_controller')
  }
  
  async createCharge(event) {
    event.preventDefault();
    // await new Promise(r => setTimeout(r, 2000));
    const request = new FetchRequest('put', this.urlValue, { responseKind: "html" })
    const response = await request.perform()
    
    if (response.ok) {
      const body = await response.html
      this.chargeTarget.innerHTML = body
    } else {
      this.chargeTarget.innerHTML = "<h1 class='text-center font-bold text-red-600'>Serviço indisponível. Tente mais tarde</h1>"
    }
  }

  async createBankBillet(event) {
    event.preventDefault();
    // await new Promise(r => setTimeout(r, 2000));
    const request = new FetchRequest('put', this.urlValue, { responseKind: "html" })
    const response = await request.perform()
    
    if (response.ok) {
      const body = await response.html
      this.bankBilletTarget.innerHTML = body
    } else {
      this.bankBilletTarget.innerHTML = "<h1 class='text-center font-bold text-red-600'>Serviço indisponível. Tente mais tarde</h1>"
    }
  }
}
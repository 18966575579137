import { Controller } from "stimulus"
import JSONEditor from 'jsoneditor';

export default class extends Controller {
  static targets = [ "hidden", "container" ]

  connect() {
    console.log('jsoneditor_controller')
  }

  initialize() {
    const container = this.containerTarget
    const hidden_field = this.hiddenTarget
    const value = hidden_field.value
    const options = { navigationBar: false, onChangeText: function(jsonString) { hidden_field.value = jsonString } }
    const editor = new JSONEditor(container, options)
    const initialJson = JSON.parse(value)
    editor.set(initialJson)
    
    document.addEventListener("turbolinks:before-cache", function() {
      if (editor !== null) {
       editor.destroy();
       editor = null;
      }
    });
  }
}